<template>
  <v-form>
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="store.name" outlined label="Name"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select v-model="store.type" :items="types" outlined label="Type" item-text="displayName"
                  return-object></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="store.address" outlined label="Address"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="store.city" outlined label="City"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-select v-model="store.state" :items="states" outlined label="State"></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="store.zipcode" outlined label="Zipcode"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="store.phone" outlined label="Phone"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="store.link" outlined label="Google Maps Link"></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  name: "StoreForm",
  data() {
    return {
      types: [],
      states: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL',
        'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT',
        'VI', 'VA', 'WA', 'WV', 'WI', 'WY'],
    }
  },
  props: {
    store: Object
  },
  created() {
    this.fetchTypes()
  },
  methods: {
    fetchTypes() {
      axios
        .get("/api/admin/store/types")
        .then((response) => {
          this.types = response.data;
        });
    },
  }
};
</script>

<style scoped>

</style>

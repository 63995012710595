<template>
  <v-container class="content">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">{{ store.name }}</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-btn dark color="red" @click="openDeleteDialog = true">
          Delete
        </v-btn>
      </v-col>
      <v-col align="right">
        <v-btn color="light-green darken-2" @click="save" :loading="loading" dark>
          Save
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Info</v-card-title>
          <v-card-text>
            <store-form :store="store"></store-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <DeleteDialog v-model="openDeleteDialog" @delete="deleteStore" :loading="loading" message="Are you sure you want to delete this store?"></DeleteDialog>
  </v-container>
</template>

<script>
import axios from "axios";
import StoreForm from "@/components/admin/StoreForm.vue";
import DeleteDialog from "@/components/general/DeleteDialog.vue";

export default {
  components: {DeleteDialog, StoreForm},
  name: "AdminStoreView",
  metaInfo: {
    title: "Store View",
  },
  data() {
    return {
      store: {},
      id: null,
      loading: true,
      openDeleteDialog: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios.get("/api/admin/store/" + this.id)
        .then((response) => {
          this.store = response.data;
        })
        .catch(() => {
          this.$store.dispatch('banner/error', {text: 'Store with id ' + this.id + ' was not found!'})
          this.$router.push({name: 'adminStoresList'})
        })
        .finally(() => {
          this.loading = false
        });
    },
    save() {
      this.loading = true;

      axios.put("/api/admin/store/" + this.store.id, this.store)
        .then(() => {
          this.$store.dispatch('banner/open', {text: 'Store Updated!'})
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteStore() {
      this.loading = true;

      axios.delete("/api/admin/store/" + this.store.id)
        .then(() => {
          this.$store.dispatch('banner/open', {text: 'Store Deleted!'})
          this.$router.push({name: 'adminStoresList'})
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="show"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 red">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div class="title pa-12">{{ message }}</div>
      </v-card-text>
      <v-card-actions>
        <v-btn tile @click="show = false" color="red" dark :loading="loading">No</v-btn>
        <v-spacer></v-spacer>
        <v-btn tile @click="del" color="green" dark :loading="loading">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  name: "DeleteDialog",
  props: {
    value: Boolean,
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    del() {
      this.$emit('delete')
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
</style>
